import React from "react";
import { cn } from "@/lib/utils";
import { useFunnel } from "../../hooks/funnelProvider";
import { CheckIcon } from "@heroicons/react/20/solid";

type StepStatus = "complete" | "current" | "upcoming";

export default function CircleStepper() {
    const { currentStep, totalSteps } = useFunnel();

    return (
        <ol role="list" className="flex items-center w-full">
            {Array.from({ length: totalSteps * 2 - 1 }, (_, idx) => {
                if (idx % 2 === 0) {
                    // Circle (Step)
                    const stepIdx = idx / 2;
                    const stepStatus: StepStatus =
                        stepIdx < currentStep
                            ? "complete"
                            : stepIdx === currentStep
                            ? "current"
                            : "upcoming";
                    return (
                        <li
                            key={`step-${stepIdx}`}
                            className="flex items-center flex-shrink-0"
                        >
                            {stepStatus === "complete" ? (
                                <div className="flex items-center justify-center h-8 w-8 rounded-full bg-blue-600">
                                    <CheckIcon className="h-5 w-5 text-white" />
                                </div>
                            ) : stepStatus === "current" ? (
                                <div className="flex items-center justify-center h-8 w-8 rounded-full border-2 border-blue-600 bg-white">
                                    <span className="h-2.5 w-2.5 rounded-full bg-blue-600" />
                                </div>
                            ) : (
                                <div className="flex items-center justify-center h-8 w-8 rounded-full border-2 border-gray-300 bg-white">
                                    <span className="h-2.5 w-2.5 rounded-full bg-transparent" />
                                </div>
                            )}
                        </li>
                    );
                } else {
                    // Line connecting steps
                    const lineIdx = (idx - 1) / 2;
                    const lineStatus =
                        lineIdx < currentStep - 1 ? "complete" : "upcoming";
                    return (
                        <li key={`line-${lineIdx}`} className="flex-1">
                            <div
                                className={cn(
                                    "h-0.5",
                                    lineStatus === "complete"
                                        ? "bg-blue-600"
                                        : "bg-gray-200"
                                )}
                            ></div>
                        </li>
                    );
                }
            })}
        </ol>
    );
}
