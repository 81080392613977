"use client";

import Logo from "@/lib/logo";
import { useFunnel } from "../../hooks/funnelProvider";
import { motion } from "framer-motion";
import { Card } from "@/components/ui/card";
import CircleStepper from "../steppers/circleStepper";

export default function NoImageLayout() {
    const { currentStep, currentStepComponent } = useFunnel();

    return (
        <div className="w-full h-screen bg-muted relative flex flex-col items-center">
            <div className="z-10 w-full min-h-[250px] h-[250px] bg-primary relative overflow-hidden">
                <div className="absolute w-[50%] h-[50%] bottom-[0] left-[-20%] bg-[#0A59EB] opacity-40 blur-[100px]"></div>
                <div className="flex h-[70px] justify-center items-center">
                    <Logo className="w-28" color="white" />
                </div>
            </div>
            <main className="z-50 -mt-[180px] px-4 flex w-full min-h-[70%] justify-center items-center">
                <Card className="h-full max-w-5xl relative flex flex-col justify-center items-center w-full">
                    <div className="w-full px-8 top-8 absolute">
                        <CircleStepper />
                    </div>
                    <motion.div
                        key={`step${currentStep}`}
                        className="w-full"
                        initial={{ x: true ? "50%" : "-50%", opacity: 0 }}
                        animate={{ x: 0, opacity: 1 }}
                        transition={{ duration: 0.3, ease: "easeInOut" }}
                    >
                        {currentStepComponent}
                    </motion.div>
                </Card>
            </main>
        </div>
    );
}
