import * as React from "react";
import { cn } from "@/lib/utils";
import { useTranslate } from "@tolgee/react";

export interface InputProps
    extends React.InputHTMLAttributes<HTMLInputElement> {
    placeholderKeyName?: string;
}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
    ({ className, type, placeholder, placeholderKeyName, ...props }, ref) => {
        const { t } = useTranslate();

        const translatedPlaceholder =
            placeholder && placeholderKeyName
                ? t(placeholderKeyName, placeholder)
                : placeholder;

        return (
            <input
                type={type}
                className={cn(
                    "flex h-9 w-full rounded-md border border-input bg-background px-2.5 py-2 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-0 disabled:cursor-not-allowed disabled:opacity-50",
                    className
                )}
                ref={ref}
                placeholder={translatedPlaceholder}
                {...props}
            />
        );
    }
);
Input.displayName = "Input";

export { Input };
