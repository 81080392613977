"use client";

import {
    createContext,
    useContext,
    useState,
    ReactNode,
    Context,
    Dispatch,
    SetStateAction,
} from "react";
import { User } from "@/features/auth/types";

interface FunnelProviderProps<Values> {
    children: ReactNode;
    user?: User | null;
    steps: FunnelStep[];
    initialValues: Values;
    nextFunnelHref?: string;
}

interface FunnelContextType<Values> {
    user?: User | null;
    currentStep: number;
    currentStepComponent: JSX.Element;
    currentStepImage?: string;
    totalSteps: number;
    getValue: <K extends keyof Values>(field: K) => Values[K];
    setValue: <K extends keyof Values>(field: K, value: Values[K]) => void;
    next: () => void;
    prev: () => void;
    loading: boolean;
    setLoading: (val: boolean) => void;
    nextFunnelHref?: string;
}

export type FunnelStep = { component: JSX.Element; image?: string };

// use this to navigate users between funnels
export type FunnelStack = { href: string }[];

const FunnelContext = createContext<FunnelContextType<unknown> | undefined>(
    undefined
);

export const useFunnel = <Values extends {}>() => {
    const context = useContext(
        FunnelContext as Context<FunnelContextType<Values> | undefined>
    );
    if (!context) {
        throw new Error("useFunnel must be used within a FunnelContext");
    }
    return context;
};

export default function FunnelProvider<Values>({
    children,
    steps,
    user,
    initialValues,
    nextFunnelHref,
}: FunnelProviderProps<Values>): JSX.Element {
    const [currentStep, setCurrentStep] = useState<number>(0);
    const [values, setValues] = useState<Values>(initialValues);
    const [loading, setLoading] = useState<boolean>(false);

    const next = () =>
        setCurrentStep((prev) => (prev < steps.length - 1 ? prev + 1 : prev));

    const prev = () => setCurrentStep((prev) => (prev > 0 ? prev - 1 : prev));

    const getValue: <K extends keyof Values>(field: K) => Values[K] = (field) =>
        values[field];

    const setValue: <K extends keyof Values>(
        field: K,
        value: Values[K]
    ) => void = (field, value) => {
        setValues((prev) => ({ ...prev, [field]: value }));
    };

    const value: FunnelContextType<Values> = {
        user,
        currentStep,
        currentStepComponent: steps[currentStep].component,
        currentStepImage: steps[currentStep].image,
        totalSteps: steps.length,
        getValue,
        setValue,
        next,
        prev,
        loading,
        setLoading,
        nextFunnelHref,
    };

    return (
        <FunnelContext.Provider value={value as any}>
            {children}
        </FunnelContext.Provider>
    );
}
