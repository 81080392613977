import { hc } from "hono/client";
import { AppType } from "@/app/api/[[...route]]/route";
import { domains, Environment } from "./domains/domains";

const baseUrl =
    typeof window !== "undefined"
        ? `${window.location.protocol}//${window.location.host}`
        : domains[process.env.NEXT_PUBLIC_ENV as Environment].urls[0];

export const client = hc<AppType>(baseUrl);
