"use client";

import { getLogoUrlClientSide } from "@/lib/domains/domains-client";
import Image from "next/image";
import { useIsClient } from "usehooks-ts";

interface LogoProps {
    className?: string;
    width?: number;
    height?: number;
    fill?: boolean;
    color?: "primary" | "white";
}

export default function Logo({
    className,
    width = 633,
    height = 238,
    fill = false,
    color = "primary",
}: LogoProps) {
    const isClient = useIsClient();

    if (isClient) {
        return (
            <Image
                className={className}
                src={getLogoUrlClientSide(color)}
                alt="logo"
                {...(fill ? { fill: true } : { width, height })}
            />
        );
    }
}
