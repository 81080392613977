"use client";

import { Button } from "@/components/ui/button";
import { useFunnel } from "./hooks/funnelProvider";
import ImageLayout from "./components/layouts/imageLayout";
import NoImageLayout from "./components/layouts/noImageLayout";

export default function Funnel() {
    const { currentStepImage } = useFunnel();

    if (currentStepImage) {
        return <ImageLayout />;
    } else {
        return <NoImageLayout />;
    }
}

export const LocalNavButtons = () => {
    const { next, prev } = useFunnel();

    if (process.env.NEXT_PUBLIC_ENV === "local") {
        return (
            <div
                className="my-8 flex flex-row gap-6 w-full justify-center items-center"
                id="just for testing delete this"
            >
                <Button onClick={() => prev()} variant="outline">
                    prev
                </Button>
                <Button onClick={() => next()} variant="outline">
                    next
                </Button>
            </div>
        );
    }
};
