"use client";

import Logo from "@/lib/logo";
import { useFunnel } from "../../hooks/funnelProvider";
import { motion } from "framer-motion";
import Image from "next/image";

export default function ImageLayout() {
    const { currentStep, currentStepComponent, currentStepImage } = useFunnel();

    if (!currentStepImage) throw new Error("no image in ImageLayout");

    return (
        <div className="w-full h-screen grid md:grid-cols-2">
            <motion.div
                key={`step${currentStep}`}
                initial={{ x: true ? "50%" : "-50%", opacity: 0 }}
                animate={{ x: 0, opacity: 1 }}
                transition={{ duration: 0.3, ease: "easeInOut" }}
            >
                {currentStepComponent}
            </motion.div>
            <div className="hidden bg-primary md:block md:h-full relative overflow-hidden">
                <div className="absolute w-[60%] h-[50%] top-[-20%] right-[-20%] bg-[#0A59EB] opacity-40 blur-[100px]"></div>
                <div className="flex flex-col h-full w-full">
                    <div className="flex h-[80px] justify-end items-center px-6">
                        <Logo className="w-28" color="white" />
                    </div>
                    <div className="flex flex-1 overflow-hidden items-center">
                        <Image
                            src={currentStepImage}
                            alt="illustration"
                            fill
                            className="object-contain"
                        />
                    </div>
                    <div className="h-[80px]"></div>
                </div>
            </div>
        </div>
    );
}
