import { getBrand, getFaviconUrl, getLogoUrl, LogoColor } from "./domains";

export const getLogoUrlClientSide = (color?: LogoColor) => {
    const host = `${window.location.protocol}//${window.location.host}`;

    const brand = getBrand(host);

    return getLogoUrl(brand, color || "primary");
};
export const getFaviconUrlClientSide = () => {
    const host = `${window.location.protocol}//${window.location.host}`;

    const brand = getBrand(host);

    return getFaviconUrl(brand);
};
