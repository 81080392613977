"use client";

import AuthCard from "@/features/auth/components/authCard/authCard";
import { useFunnel } from "@/features/funnel/hooks/funnelProvider";
import { HOMEPAGE } from "@/lib/utils";
import { useRouter } from "next/navigation";

export default function AuthStep() {
    const { nextFunnelHref } = useFunnel();
    const router = useRouter();

    return (
        <div className="flex w-full h-full justify-center items-center">
            <div className="flex w-full max-w-[380px]">
                <AuthCard
                    initialAction="signup"
                    socialProviders={["google"]}
                    textsWithLinks={{}}
                    isPlatform
                    tenantId={null}
                    noBorder
                    successCallback={(uid) => {
                        if (nextFunnelHref) {
                            router.replace(nextFunnelHref);
                        } else {
                            router.replace(HOMEPAGE);
                        }
                    }}
                />
            </div>
        </div>
    );
}
